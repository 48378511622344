import * as FullStory from '@fullstory/browser'

export const bootSession = function (session) {
  const featureFlags = session.get('feature_flags') || session.get('whitelist')

  if (window.rollbar) {
    window.rollbar.configure({
      payload: {
        person: {
          id: session.get('_id'),
          email: session.get('email')
        }
      }
    })
  }

  if (window.zE && window.zE instanceof Function) {
    window.zE(function () {
      window.zE.identify({
        name: session.get('display_name'),
        email: session.get('email')
      })
    })
  } else {
    if (window.rollbar) {
      window.rollbar.error('Unable to load Zendesk widget')
    }
  }

  if (featureFlags.includes('session_recording')) {
    FullStory.init({ orgId: 'PRRPK', debug: true })
    FullStory.identify(session.get('_id'), {})
  }
}
