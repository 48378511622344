import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width } from 'styled-system'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Button from 'components/Button'
import { apiBaseURI } from 'utils/api'
import { Outlook, Wordmark } from 'svg'
import { fadeInRule } from 'utils/animations'
import theme from '../../../../themes/light'

const LogoContainer = styled.div`
  padding-bottom: 5rem;
`

const Wrapper = styled.div`
  max-width: 342px;
  padding-bottom: 5rem;
  animation: ${fadeInRule};
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

const Terms = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  color: ${theme.colors.gray60};
  font: ${theme.fonts.small};
  text-align: left;
  margin-top: 2rem;

  ${space}
  ${width}
`

class MicrosoftLogin extends Component {
  UNSAFE_componentWillMount () {
    const {
      signup
    } = this.props
    document.title = signup ? 'Interseller | Signup - Microsoft' : 'Interseller | Login - Microsoft'
  }

  render () {
    const {
      discovery,
      signup
    } = this.props

    const verb = signup ? 'up' : 'in'
    const url = discovery.getIn(['data', 'url']) || `${apiBaseURI}/auth/microsoft`
    const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}`
    const label = `We've detected that you're using Outlook. Please click the button below to sign ${verb} using Outlook.`

    const name = discovery.getIn(['data', 'identity', 'name', 'first'])
    let welcome
    if (name) {
      welcome = `${signup ? 'Welcome' : 'Welcome back'} ${name}!`
    }

    return (
      <Wrapper>
        <LogoContainer>
          <Wordmark />
        </LogoContainer>
        {welcome && <Title mb='0.5rem'>{welcome}</Title>}
        {!welcome && <Title>{`Sign ${verb} with Outlook 365`}</Title>}
        <Label mt='1rem'>{label}</Label>
        <ButtonContainer>
          <Button
            ref={(button) => { this.outlookButton = button }}
            primary
            full
            mt='1rem'
            bg={theme.colors.outlook}
            label={`Sign ${verb} with Outlook`}
            icon={<Outlook />}
            handleClick={() => {
              if (url.indexOf('?') > -1) {
                window.location = `${url}&redirect=${redirect}`
              } else {
                window.location = `${url}?redirect=${redirect}`
              }
            }}
          />
        </ButtonContainer>
        {signup &&
          <Terms>
            Not using Outlook? You can also sign up with your <a href='/signup/google'>Gmail</a> account.
          </Terms>}
      </Wrapper>
    )
  }
}

MicrosoftLogin.propTypes = {
  discovery: PropTypes.object,
  signup: PropTypes.bool
}

export default MicrosoftLogin
