import React, { Component } from 'react'
import { withRouter } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { apiBaseURI } from 'utils/api'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Modal from 'components/Modal'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import LogoImage from 'elements/LogoImage'
import { CRM_TYPES } from 'containers/Integrations/constants'
import theme from '../../../../../themes/light'

const Wrapper = styled.div`
  background: ${theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  width: 100%;
  text-align: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const FieldContainer = styled.div`
  padding-left: 2rem;
  padding-right: 2rem;
`

class ConnectCRM extends Component {
  state = {}

  UNSAFE_componentWillMount () {
    this.checkForRedirect(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.checkForRedirect(nextProps)
  }

  checkForRedirect = (props) => {
    const {
      crmList,
      params,
      location
    } = props
    const crmMap = (crmList.get('data') || []).reduce((acc, crm) => {
      acc[crm.get('type')] = crm
      return acc
    }, {})
    const crm = crmMap[params.type]
    if (crm) {
      const url = crm.get('url')
      const type = crm.get('type')
      const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}/integrations/crm`
      if (url && !location.query.missing) {
        window.location = url
      } else if (
        (crm.get('oauth') || crm.get('owner_connection')) &&
        !location.query.missing
      ) {
        window.location = `${apiBaseURI}/auth/crm/${type}?redirect=${redirect}`
      }
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.state.email && this.state.email.length > 0) {
      e.preventDefault()
      return this.props.connectCRM(this.props.params.type, this.state)
    }
    return false
  }

  descriptionForCRM = (crm) => {
    const type = crm.get('type')

    switch (type) {
      case CRM_TYPES.CLOSEIO:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to generate an API key from <a href='https://app.close.io/settings/' target='_new'>Close</a> by accessing <strong>Settings&nbsp;&rarr;&nbsp;Your&nbsp;API&nbsp;Keys</strong> case
          </Label>
        )
      case CRM_TYPES.PIPEDRIVE:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to copy and paste your API token from <a href='https://www.pipedrive.com/en/login' target='_new'>Pipedrive</a> under the <strong>Top right menu &rarr;&nbsp;Settings&nbsp;&rarr;&nbsp;API</strong>
          </Label>
        )
      case CRM_TYPES.SALESFORCEIQ:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to generate a custom integration from <a href='https://app.salesforceiq.com/app.html#/settings?v=org-integrations' target='_new'>SalesforceIQ</a> which is located at the bottom of <strong>Organization Settings&nbsp;&rarr;&nbsp;Integrations</strong>
          </Label>
        )
      case CRM_TYPES.PROSPERWORKS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to generate an API Key from <a href='https://app.prosperworks.com' target='_new'>Copper</a> which can be done by visiting <strong>Settings&nbsp;&rarr;&nbsp;API&nbsp;Keys</strong>
          </Label>
        )
      case CRM_TYPES.CATS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to generate an API Key from <a href='https://catsone.com/' target='_new'>CATS</a> by visiting <strong>Settings&nbsp;&rarr;&nbsp;Administration&nbsp;&rarr;&nbsp;API&nbsp;Keys</strong>
          </Label>
        )
      case CRM_TYPES.BULLHORN:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Connecting with Bullhorn requires some manual setup on our end, please take a look at this <a href='https://help.interseller.io/article/187-setting-up-the-bullhorn-integration' target='_new'>guide</a> and <a href='' onClick='window.zE.activate()'> get in contact</a> with us to get started.
          </Label>
        )
      case CRM_TYPES.CLOCKWORK:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to get the API Key, Secret Key, and Firm's Key from Clockwork. You can do so by going to <strong>Profile&nbsp;&rarr;&nbsp;API Keys&nbsp;&rarr;&nbsp;Create</strong>.
          </Label>
        )
      case CRM_TYPES.JAZZHR:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to get the API Key from <a href='https://app.jazz.co/app/settings/integrations' target='_new'>JazzHR</a> by visiting <strong>Settings&nbsp;&rarr;&nbsp;Integrations</strong>
          </Label>
        )
      case CRM_TYPES.INSIGHTLY:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to get the API Key from <a href='https://crm.na1.insightly.com/users/usersettings' target='_new'>Insightly</a> by visiting <strong>User Settings&nbsp;&rarr;&nbsp;API Key</strong>
          </Label>
        )
      case CRM_TYPES.ZOHO_RECRUIT:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to create an Auth Token from Zoho Recruit's <a href='https://accounts.zoho.com/apiauthtoken/create?SCOPE=ZohoRecruit/recruitapi&DISPLAY_NAME=Interseller' target='_new'>API Page</a>
          </Label>
        )
      case CRM_TYPES.RECRUITEE:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to obtain your Company ID and a acess token on Recruitee's <a href='https://app.recruitee.com/#/settings/api_tokens' target='_new'>Personal API Tokens</a>
          </Label>
        )
      case CRM_TYPES.EXELARE:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to use the same login information that you use with Exelare.
          </Label>
        )
      case CRM_TYPES.CRELATE:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to get your API key from Crelate by visiting <a href='https://app.crelate.com/go#user/userapi' target='_new'>Personal Settings&nbsp;&rarr;&nbsp;API Access</a>
          </Label>
        )
      case CRM_TYPES.PCRECRUITER:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You will need to use your PCRecruiter login information and obtain your API Database ID from PCRecruiter by visiting <strong>System&nbsp;&rarr;&nbsp;API&nbsp;&rarr;&nbsp;API Settings</strong>
          </Label>
        )
      case CRM_TYPES.BREEZY:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Please log in using your Breezy credentials.
          </Label>
        )
      case CRM_TYPES.INVENIAS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Connecting with Invenias requires some manual setup on our end, please take a look at this <a href='https://help.interseller.io/article/205-invenias-integration' target='_new'>guide</a> and <a href='' onClick='window.zE.activate()'>get in contact</a> with us to get started.
          </Label>
        )
      case CRM_TYPES.RECRUITERFLOW:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Please get in touch with the Recruiterflow team to obtain an API Key.
          </Label>
        )
      case CRM_TYPES.COMPAS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Please get in touch with the COMPAS support team to obtain an API Key.
          </Label>
        )
      case CRM_TYPES.LEVER:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You'll need to generate an API key over at Lever's <a href='https://hire.lever.co/settings/integrations' target='_new'>integration settings</a>. You will need API access and be a super admin in order to generate one.
          </Label>
        )
      case CRM_TYPES.PIPELINEDEALS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You'll need to generate an API key over at PipelineDeals' <a href='https://app.pipelinedeals.com/admin/#/api' target='_new'>account settings</a>.
          </Label>
        )
      case CRM_TYPES.VINCERE:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Connecting with Vincere requires some manual setup on our end, please take a look at this <a href='https://help.interseller.io/article/219-using-interseller-with-vincere' target='_new'>guide</a> and <a href='' onClick='window.zE.activate()'>get in contact</a> with us to get started.
          </Label>
        )
      case CRM_TYPES.LEO:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Please get in touch with the Leo team to obtain an API Key.
          </Label>
        )
      case CRM_TYPES.LOXO:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Please get in touch with the Loxo team to obtain your API credentials.
          </Label>
        )
      case CRM_TYPES.DYNAMICS:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            To connect with Microsoft Dynamics CRM, please get in touch with our support team. Please have your instance URL handy.
          </Label>
        )
      case CRM_TYPES.LESSANNOYING:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You'll need to generate an API key over at Less Annoying's <a href='https://account.lessannoyingcrm.com/login/' target='_new'>account settings</a>.
          </Label>
        )
      case CRM_TYPES.RECRUITLY:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You'll need to generate an API key over at Recruitly's <a href='https://secure.recruitly.io/settings/api/private' target='_new'>My API Keys</a> setting page.
          </Label>
        )
      case CRM_TYPES.AFFINITY:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            You'll need to generate an API key over Affinity's settings page.
          </Label>
        )
      case CRM_TYPES.ERECRUIT:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Your Erecruit administrator will need to create API credentials for you to integrate. Copy and paste your Login URL, Client ID, and Client Secret below.
          </Label>
        )
      default:
        return (
          <Label mt='0.5rem' mb='0.5rem' align='center'>Enter API Key</Label>
        )
    }
  }

  fieldsForCRM = (crm) => {
    if (crm.get('type') === CRM_TYPES.BULLHORN) {
      return null
    }

    if (crm.get('type') === CRM_TYPES.VINCERE) {
      return null
    }

    const authToken = crm.get('auth_token')
    if (Immutable.List.isList(authToken)) {
      return authToken.map((field, i) => (
        <Input
          key={`${field.get('value')}`}
          data-index={i}
          label={field.get('name')}
          inputId={field.get('value')}
          onValueChange={(value) => {
            const auth = this.state.auth || {}
            auth[field.get('value')] = value
            this.setState({
              auth
            })
          }}
          mt='1.5rem'
        />
      ))
    }

    return (
      <Input
        label={authToken}
        inputId='auth'
        placeholder=''
        onValueChange={(value) => {
          this.setState({
            auth: value
          })
        }}
        mt='1.5rem'
      />
    )
  }

  render () {
    const {
      crmList,
      loading,
      params,
      router,
      connectCRM,
      location
    } = this.props

    const {
      auth
    } = this.state

    const crmMap = (crmList.get('data') || []).reduce((acc, crm) => {
      acc[crm.get('type')] = crm
      return acc
    }, {})
    const crm = crmMap[params.type]

    if (
      !crm ||
      loading ||
      (crm.get('oauth') && !location.query.missing) ||
      (crm.get('owner_connection') && !location.query.missing)
    ) {
      return (
        <Modal isOpen>
          <Loading />
        </Modal>
      )
    }

    const name = crm.get('name')
    const type = crm.get('type')
    const description = this.descriptionForCRM(crm)
    const fields = this.fieldsForCRM(crm)
    const authToken = crm.get('auth_token')

    let disabled = true
    if (Immutable.List.isList(authToken) && auth) {
      disabled = !authToken.map((field) => {
        const fieldValue = auth[field.get('value')]
        return fieldValue && fieldValue.length
      }).every(value => value)
    } else if (this.state.auth && this.state.auth.length) {
      disabled = false
    }

    return (
      <Modal
        isOpen
        onModalClose={() => {
          router.push('/integrations/crm')
        }}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <LogoImage mb='1rem' ml='auto' mr='auto' type={type} />
            <Title align='center'>Connect with {name}</Title>
            {description}
            {fields && <FieldContainer>{fields}</FieldContainer>}
          </InputContainer>
          {fields && <Divider />}
          {fields &&
            <ButtonContainer>
              <Button
                label='cancel'
                onClick={() => {
                  router.push('/integrations/crm')
                }}
                mr='0.5rem'
              />
              <Button
                primary
                disabled={disabled}
                label='connect'
                onClick={() => {
                  connectCRM(type, this.state)
                }}
              />
            </ButtonContainer>}
        </Wrapper>
      </Modal>
    )
  }
}

ConnectCRM.propTypes = {
  loading: PropTypes.bool,
  crmList: PropTypes.object,
  connectCRM: PropTypes.func,
  params: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object
}

ConnectCRM.defaultProps = {
  loading: false
}

export default withRouter(ConnectCRM)
